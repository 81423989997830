export default {
    data() {
        return {
            menus: [],
            active: '',
            menuPath: [],
            rootMenu: false
        };
    },
    watch: {
        $route() {
            if (!this.prePath) {
                this.$nextTick(() => {
                    this.findActive();
                });
            } else {
                this.prePath = '';
            }
        }
    },
    methods: {
        findActive() {
            this.active = '';
            this.menuPath = [];
            let route = {
                ...this.$route
            };

            if (this.rootMenu && this.$route.meta.prePath) {
                route = {
                    ...route,
                    ...this.parsePath(this.$route.meta.prePath)
                };
            }
            if (this.rootMenu && this.prePath) {
                route = {
                    ...route,
                    ...this.parsePath(this.prePath)
                };
            }
            const findActive = (parents, childMenus) => {
                childMenus.forEach(i => {
                    let parents_copy = [...parents];
                    let _route = this.parsePath(i.path);
                    let _flag = true;

                    Object.keys(_route.query).forEach(item => {
                        if (route.query[item] !== _route.query[item]) {
                            _flag = false;
                        }
                    });
                    if (_route.path === route.path && _flag) {
                        parents_copy.push(i);
                        this.menuPath = parents_copy.map(i => {
                            return {
                                name: i.name,
                                children: i.children
                            };
                        });
                        this.active = i.path.replace(/ /g, '');
                    }
                    if (i.children && i.children.length > 0) {
                        parents_copy.push(i);
                        findActive(parents_copy, i.children);
                        if (i.path === this.active) {
                            this.active = i.children[0].path;
                        }
                    }
                });
            };
            findActive([], this.menus);
            if (!this.active) {
                this.$EventBus.$emit('noActive');
            }
        },
        parsePath(path = '') {
            let hash = '';
            let query = {};

            let hashIndex = path.indexOf('#');
            if (hashIndex >= 0) {
                hash = path.slice(hashIndex);
                path = path.slice(0, hashIndex);
            }

            let queryIndex = path.indexOf('?');
            if (queryIndex >= 0) {
                query = this.parseQuery(path.slice(queryIndex + 1));
                path = path.slice(0, queryIndex);
            }

            return {
                path,
                query,
                hash
            };
        },
        parseQuery(query) {
            const res = {};

            query = query.trim().replace(/^(\?|#|&)/, '');

            if (!query) {
                return res;
            }

            query.split('&').forEach(param => {
                const parts = param.replace(/\+/g, ' ').split('=');
                const key = decode(parts.shift());
                const val = parts.length > 0 ? decode(parts.join('=')) : null;

                if (res[key] === undefined) {
                    res[key] = val;
                } else if (Array.isArray(res[key])) {
                    res[key].push(val);
                } else {
                    res[key] = [res[key], val];
                }
            });

            function decode(str = '') {
                try {
                    return decodeURIComponent(str);
                } catch (err) {
                    if (process.env.NODE_ENV !== 'production') {
                        //   warn(false, `Error decoding "${str}". Leaving it intact.`)
                    }
                }
                return str;
            }

            return res;
        },
        scrollTo(isFirst = false) {
            this.$nextTick(() => {
                let top = window.innerWidth * 0.2625 > 341 ? window.innerWidth * 0.2625 : 341;
                setTimeout(
                    () => {
                        window.scrollTo({
                            top: top + 200
                        });
                    },
                    isFirst ? 500 : 0
                );
            });
        }
    }
};
