var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"center":"","visible":_vm.register,"width":"560px"},on:{"update:visible":function($event){_vm.register=$event}}},[_c('div',{staticClass:"tips"},[(_vm.identification === 1)?_c('div',{staticClass:"tips_con"},[_vm._v("注册账号")]):_c('div',{staticClass:"tips_con"},[_vm._v("重置密码")])]),_c('div',{staticClass:"register"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"0"}},[_c('div',[_c('el-form-item',{attrs:{"prop":"phone","rules":{
                            required: true,
                            pattern: /^1[3-9]\d{9}$/,
                            message: '请输入正确的手机号',
                            trigger: 'blur'
                        }}},[_c('span',{staticClass:"el-icon-user"}),_c('el-select',{staticStyle:{"width":"90px"},attrs:{"placeholder":"请选择"},model:{value:(_vm.form.region),callback:function ($$v) {_vm.$set(_vm.form, "region", $$v)},expression:"form.region"}},_vm._l((_vm.list),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('el-input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入手机号"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],1),_c('div',[_c('el-form-item',{attrs:{"prop":"code","rules":{
                            required: true,
                            message: '请输入验证码',
                            trigger: 'blur'
                        }}},[_c('span',{staticClass:"icon"},[_c('img',{staticClass:"verification_code_img",attrs:{"src":require("../../assets/login_icon_yanzhengma@3x (1).png"),"alt":""}})]),_c('div',[_c('el-input',{staticStyle:{"width":"330px"},attrs:{"maxlength":"4","placeholder":"请输入短信验证码"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}},[(!_vm.form.phone)?_c('div',{staticClass:"code",attrs:{"slot":"suffix","type":"primary","underline":false},slot:"suffix"},[_vm._v(" 发送验证码 ")]):_vm._e(),(_vm.form.phone)?_c('div',{staticClass:"code1",attrs:{"slot":"suffix","type":"primary","underline":false},on:{"click":_vm.send},slot:"suffix"},[_vm._v(" "+_vm._s(_vm.time === 0 ? '发送验证码' : `重新发送${_vm.time}s`)+" ")]):_vm._e()])],1)])],1),_c('div',[_c('el-form-item',{attrs:{"prop":"password","rules":{
                            required: true,
                            message: '请输入密码',
                            trigger: 'blur'
                        }}},[_c('span',{staticClass:"el-icon-unlock"}),_c('el-input',{staticStyle:{"width":"330px"},attrs:{"type":"password","autocomplete":"off","placeholder":"请输入8-16位密码，建议数字、大小写字母组合"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1)]),_c('div',{staticClass:"xieyi"},[_c('el-checkbox',{model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[[_c('img',{staticClass:"img-icon",attrs:{"src":_vm.checked ? _vm.activeIcon : _vm.inactiveIcon}})],_c('el-link',{staticStyle:{"margin-left":"23px"},on:{"click":function($event){$event.preventDefault();_vm.$refs.argeement.flag = true}}},[_vm._v("点击同意注册协议")])],2)],1),(_vm.identification == 1)?_c('div',{staticClass:"sub-btn",staticStyle:{"width":"300px"}},[_c('div',{staticClass:"btn-block",on:{"click":_vm.doRegister}},[_vm._v("注册")]),_c('div',{staticClass:"btn-blocks",on:{"click":_vm.loginNumber}},[_vm._v("已有账号，马上登录")])]):_c('div',{staticClass:"sub-btn",staticStyle:{"width":"300px"}},[_c('div',{staticClass:"btn-block",on:{"click":_vm.password}},[_vm._v("确定")]),_c('div',{staticClass:"btn-blocks",on:{"click":function($event){$event.preventDefault();(_vm.$refs.agreement2.login = true), (_vm.register = false)}}},[_vm._v(" 想起密码，立即登录 ")])])],1)]),_c('agreement',{ref:"argeement"}),_c('Agreement2',{ref:"agreement2"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }