<template>
    <div class="menu-content">
        <div
            v-if="
                this.$route.name !== 'register' &&
                this.$route.name !== 'login' &&
                this.$route.name !== 'authenticationfail' &&
                this.$route.name !== 'authenticationsfail' &&
                this.$route.name !== 'registration' &&
                this.$route.name !== 'enterprise' &&
                this.$route.name !== 'allsearch' &&
                this.$route.name !== 'authentication' &&
                this.$route.name !== 'authentications' &&
                this.$route.name !== 'personal'
            "
        >
            <div class="menu" :style="{ position: fixed ? 'fixed' : 'absolute' }">
                <el-menu
                    mode="horizontal"
                    :background-color="$colors.warn"
                    text-color="#fff"
                    active-text-color="#fff"
                    unique-opened
                    router
                    ref="menu"
                >
                    <template v-for="item in menus">
                        <el-menu-item
                            v-if="item.children.length === 0"
                            :index="`${item.path || item.id}`"
                            :key="item.id"
                            :name="item.id"
                            style="width: 160px"
                            >{{ item.name }}</el-menu-item
                        >

                        <el-submenu
                            v-else
                            :name="item.id"
                            :key="item.id"
                            :index="`${item.path || item.id}`"
                            popper-class="popup-menu"
                            style="width: 208px"
                        >
                            <template slot="title">{{ item.name }}</template>
                            <el-menu-item
                                :index="`${child.path || child.id}`"
                                :key="child.id"
                                v-for="child in item.children"
                                >{{ child.name }}</el-menu-item
                            >
                        </el-submenu>
                    </template>
                </el-menu>
            </div>
        </div>
    </div>
</template>

<script>
import menu from '../mixins/menu';
export default {
    name: 'Menu',
    mixins: [menu],
    data() {
        return {
            fixed: false,
            rootMenu: true
        };
    },
    watch: {
        $route() {
            this.active = '';
        }
    },
    mounted() {
        this.$EventBus.$on('setPrePath', res => {
            // console.log(res);
            if (res) {
                this.prePath = res;
            }
        });
        window.addEventListener('scroll', this.scroll);
        this.$nextTick(() => {
            this.scroll();
        });
        this.$http
            .post('navigation/backAll', {
                type: 'TOTAL'
            })

            .then(res => {
                this.menus = res;
                console.log(res);
                this.active = '';
            });
    },
    methods: {
        scroll() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop >= 180) {
                this.fixed = true;
            } else {
                this.fixed = false;
            }
        }
    },
    beforeDestroy() {
        this.$EventBus.$off('setPrePath');
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    }
};
</script>

<style lang="less" scoped>
.menu {
    .el-menu {
        .center-content();

        border-width: 0;

        /deep/ .el-menu-item {
            // color: #fff;
            font-size: @menuFont;
            padding: 0 24px;
            text-align: center;
            box-sizing: border-box;
            &.is-active {
                background-color: transparent !important;
                // font-weight: bold;
                border-width: 0;
            }
            &:hover {
                background-color: @prim !important;
                border-width: 0;
                font-weight: bold;
            }
        }

        /deep/ .el-submenu {
            .el-submenu__title {
                font-size: @menuFont;
                padding: 0 24px;
                box-sizing: border-box;
                i {
                    display: none;
                }
                text-align: center;
                &:hover {
                    background-color: @prim !important;
                    border-width: 0;
                }
            }
            &.is-active {
                .el-submenu__title {
                    background-color: transparent !important;
                    border-width: 0;
                    // font-weight: bold;
                }
            }
            &.is-opened {
                .el-submenu__title {
                    background-color: @prim !important;
                    border-width: 0;
                    font-weight: bold;
                }
            }
        }
    }
    background-color: @warn;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
}

.menu-content {
    height: 60px;
    position: relative;
}
</style>
