import { render, staticRenderFns } from "./Agreement3.vue?vue&type=template&id=641f8dbf&scoped=true"
import script from "./Agreement3.vue?vue&type=script&lang=js"
export * from "./Agreement3.vue?vue&type=script&lang=js"
import style0 from "./Agreement3.vue?vue&type=style&index=0&id=641f8dbf&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641f8dbf",
  null
  
)

export default component.exports