<template>
    <div>
        <el-dialog class="tips" title="提交留言" center :visible.sync="show" width="760px">
            <div class="title">留言须知</div>
            <div class="content">
                1、请您自觉遵守中华人民共和国宪法和法律；<br />
                2、您应对来信内容的真实性、客观性负责，信中不要含有猥亵、色情、造谣诽谤、人身攻击和反政府言论。否则，您将承担由此而引发的一切法律责任；<br />
                3、不得发表未经证实的消息，亲身经历请注明；<br />
                4、请勿发表与军民融合无关的留言，以及任何形式的广告和推介企业产品或服务等内容；<br />
                5、请您真实、准确地填写您联系方式的信息，以便与您联系，我们将依法保护您的隐私权，保守您的联系方式等信息秘密；<br />
                6、您联系方式的信息如不真实、不准确，留言将可予以删除；<br />
                7、本平台拥有发布、编辑、删除网上留言的权利，凡不符合本须知规定的留言将予以删除；<br />
                8、如在本平台留言，即表明已阅读并接受了上述各项条款。
            </div>

            <div class="btns">
                <el-button type="info" size="small" @click="show = false">返回</el-button>
                <el-button type="primary" size="small" @click="add">确定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="我要留言" center :visible.sync="showForm" width="760px">
            <el-form
                :model="formData"
                ref="form"
                :rules="rules"
                style="width: 652px"
                label-width="110px"
                inline
                size="small"
            >
                <el-form-item prop="name" label="姓名">
                    <el-input v-model="formData.name"></el-input>
                </el-form-item>
                <el-form-item prop="occupation" label="职业">
                    <el-input v-model="formData.occupation"></el-input>
                </el-form-item>
                <el-form-item prop="phone" label="电话">
                    <el-input v-model="formData.phone"></el-input>
                </el-form-item>
                <el-form-item prop="email" label="邮箱">
                    <el-input v-model="formData.email"></el-input>
                </el-form-item>
                <el-form-item prop="type" label="证件类型">
                    <el-select v-model="formData.type" clearable filterable placeholder="请选择" style="width: 100%">
                        <el-option
                            v-for="item in typeOptionsList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="documentNumber" v-if="formData.type" label="证件编号">
                    <el-input v-model="formData.documentNumber"></el-input>
                </el-form-item>
                <el-form-item prop="title" label="咨询标题" class="block">
                    <el-input v-model="formData.title" placeholder="请输入标题内容，30字以内"></el-input>
                </el-form-item>
                <el-form-item prop="content" label="正文详情" class="block">
                    <el-input
                        type="textarea"
                        :rows="4"
                        v-model="formData.content"
                        placeholder="请写明问题有关时间/地点/事实等相关信息，不超过1000字"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="img" label="图片" class="block">
                    <multi-upload v-model="formData.img"></multi-upload>
                </el-form-item>
            </el-form>

            <div class="btns">
                <el-button type="info" size="small" @click="showForm = false">返回</el-button>
                <el-button type="primary" size="small" @click="onSave">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { phonePattern } from '../../utils/variables';
export default {
    data() {
        return {
            needChange: true,
            show: false,
            showForm: false,
            formData: {},
            list: [],
            rules: {
                documentNumber: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (this.formData.type === 'ID_CARD') {
                                const reg = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
                                if (!reg.test(value) && value != '') {
                                    callback(new Error('请输入正确的身份证号'));
                                } else {
                                    callback();
                                }
                            } else {
                                var reg = /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/;
                                if (reg.test(value) === false) {
                                    callback(new Error('军官证号不合规'));
                                } else {
                                    callback();
                                }
                            }
                        }
                    }
                ],
                name: [{ required: true, message: '请输入商标名称' }],
                type: [{ required: true, message: '请选择证件类型' }],
                phone: { required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' },
                email: [{ type: 'email', required: false, message: '请输入邮箱', trigger: 'blur' }],
                title: [{ required: true, message: '请输入咨询标题' }],
                content: [{ required: true, message: '请输入正文详情' }]
            },
            typeOptionsList: [
                { label: '身份证', value: 'ID_CARD' },
                { label: '军官证', value: 'OFFICER_ID' }
            ]
        };
    },
    computed: {
        ...mapState(['userInfo']),
        Type() {
            return this.userInfo.authorities.filter(item => {
                return item.name === 'ROLE_PERSONAL';
            });
        }
    },
    mounted() {
        this.$EventBus.$on('postMessage', res => {
            // console.log(res);
            this.show = true;
            if (this.$refs.form) {
                this.$refs.form.resetFields();
            }
        });
    },
    methods: {
        add() {
            (this.show = false), (this.showForm = true);
            this.formData = {
                name: this.userInfo.nickname,
                phone: this.userInfo.phone,
                email: this.userInfo.email
            };
            if (this.isLogin && this.Type[0].name === 'ROLE_PERSONAL') {
                this.$http
                    .get('/personal/my')
                    .then(res => {
                        this.formData = {
                            ...res,
                            name: this.userInfo.nickname,
                            phone: this.userInfo.phone,
                            email: this.userInfo.email
                        };
                        console.log("1313",res)
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },
        onSave() {
            this.$refs.form.validate(valid => {
                console.log(valid);
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };
            delete data.id;
            this.saving = true;
            this.$http
                .post('/message/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.showForm = false;
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        }
    },
    beforeDestroy() {
        this.$EventBus.$off('postMessage');
    }
};
</script>

<style lang="less" scoped>
.tips {
    /deep/ .el-dialog {
        .el-dialog__body {
            padding: 20px 88px 40px;

            .btns {
                padding: 50px 0 0;
            }
        }

        .el-form-item {
            width: 310px;
        }
    }
}
.title {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    line-height: 17px;
}

.content {
    font-size: 14px;
    color: #000000;
    line-height: 24px;
    margin-top: 10px;
}
</style>
