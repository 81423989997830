<template>
    <div>
        <el-dialog center :visible.sync="register" width="560px">
            <div class="tips">
                <div class="tips_con" v-if="identification === 1">注册账号</div>
                <div class="tips_con" v-else>重置密码</div>
            </div>
            <div class="register">
                <el-form :model="form" ref="form" :rules="rules" label-width="0">
                    <div>
                        <el-form-item
                            prop="phone"
                            :rules="{
                                required: true,
                                pattern: /^1[3-9]\d{9}$/,
                                message: '请输入正确的手机号',
                                trigger: 'blur'
                            }"
                        >
                            <span class="el-icon-user"></span>
                            <el-select style="width: 90px" v-model="form.region" placeholder="请选择">
                                <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                            <el-input v-model="form.phone" style="width: 240px" placeholder="请输入手机号"> </el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item
                            prop="code"
                            :rules="{
                                required: true,
                                message: '请输入验证码',
                                trigger: 'blur'
                            }"
                        >
                            <span class="icon">
                                <img
                                    src="../../assets/login_icon_yanzhengma@3x (1).png"
                                    alt=""
                                    class="verification_code_img"
                                />
                            </span>
                            <div>
                                <el-input
                                    style="width: 330px"
                                    maxlength="4"
                                    v-model="form.code"
                                    placeholder="请输入短信验证码"
                                >
                                    <div
                                        v-if="!form.phone"
                                        class="code"
                                        type="primary"
                                        :underline="false"
                                        slot="suffix"
                                    >
                                        发送验证码
                                    </div>
                                    <div
                                        v-if="form.phone"
                                        @click="send"
                                        class="code1"
                                        type="primary"
                                        :underline="false"
                                        slot="suffix"
                                    >
                                        {{ time === 0 ? '发送验证码' : `重新发送${time}s` }}
                                    </div>
                                </el-input>
                            </div>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item
                            prop="password"
                            :rules="{
                                required: true,
                                message: '请输入密码',
                                trigger: 'blur'
                            }"
                        >
                            <span class="el-icon-unlock"></span>
                            <el-input
                                style="width: 330px"
                                v-model="form.password"
                                type="password"
                                autocomplete="off"
                                placeholder="请输入8-16位密码，建议数字、大小写字母组合"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <!-- <el-form-item prop="pass">
                        <span class="el-icon-unlock"></span>
                        <el-input
                            style="width: 300px"
                            v-model="form.pass"
                            type="password"
                            autocomplete="off"
                            placeholder="请再次输入密码"
                        ></el-input>
                    </el-form-item> -->
                </el-form>
                <div class="xieyi">
                    <el-checkbox v-model="checked">
                        <template>
                            <img class="img-icon" :src="checked ? activeIcon : inactiveIcon" />
                        </template>
                        <el-link style="margin-left: 23px" @click.prevent="$refs.argeement.flag = true"
                            >点击同意注册协议</el-link
                        >
                    </el-checkbox>
                </div>
                <div class="sub-btn" style="width: 300px" v-if="identification == 1">
                    <div class="btn-block" @click="doRegister">注册</div>
                    <div class="btn-blocks" @click="loginNumber">已有账号，马上登录</div>
                </div>
                <div class="sub-btn" style="width: 300px" v-else>
                    <div class="btn-block" @click="password">确定</div>
                    <div class="btn-blocks" @click.prevent="($refs.agreement2.login = true), (register = false)">
                        想起密码，立即登录
                    </div>
                </div>
            </div>
        </el-dialog>
        <agreement ref="argeement"></agreement>
        <Agreement2 ref="agreement2"></Agreement2>
    </div>
</template>

<script>
import Agreement from '../../components/popup/Agreement.vue';
import Agreement2 from '../../components/popup/Agreement2.vue';
// export default {
//     data() {
//         return {
//             register: false,
//             identification: 0
//         };
//     }
export default {
    data() {
        return {
            register: false,
            identification: 0,
            show: false,
            form: {
                region: '0'
            },
            activeIcon: require('../../assets//png-Selected@3x.png'),
            inactiveIcon: require('../../assets/png-Unselected@3x.png'),
            checked: false,
            time: 0,
            rules: {
                password: [{ min: 8, max: 16, message: '密码长度在 8 到 16 个字符', trigger: 'blur' }],
                pass: [
                    {
                        validator: (rule, value, callback) => {
                            if (value !== this.form.password) {
                                callback(new Error('两次密码输入不一致'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    },
                    { required: true, message: '请再次输入密码', trigger: 'blur' }
                ]
            },
            list: [{ id: '0', name: 'CH +86' }]
        };
    },
    methods: {
        loginNumber() {
            this.register = false;
            this.$refs.agreement2.login = true;
        },
        send() {
            this.sendCode(this.form.phone);
        },
        password() {
            if (this.checked == false) {
                this.$message.warning('请点击同意注册协议');
                return;
            }
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.$http
                        .get('/sms/verify', {
                            phone: this.form.phone,
                            code: this.form.code
                        })
                        .then(() => {
                            this.$http
                                .post('/user/forgotPassword', {
                                    phone: this.form.phone,
                                    password: this.form.password
                                })
                                .then(res => {
                                    localStorage.setItem('webToken', res);
                                    this.$message.warning('修改成功');
                                    return this.$store.dispatch('getUserInfo');
                                })
                                .then(() => {
                                    this.register = false;
                                    // this.$router.replace('/');
                                })
                                .catch(e => {
                                    this.$message.error(e.error);
                                });
                        })
                        .catch(e => {
                            this.$message.error(e.error);
                        });
                }
            });
        },
        doRegister() {
            if (this.checked == false) {
                this.$message.warning('请点击同意注册协议');
                return;
            }
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$http
                        .get('/sms/verify', {
                            phone: this.form.phone,
                            code: this.form.code
                        })
                        .then(() => {
                            this.$http
                                .post('/auth/register', {
                                    phone: this.form.phone,
                                    password: this.form.password
                                })
                                .then(res => {
                                    localStorage.setItem('webToken', res);
                                    this.$message.warning('注册成功');
                                    this.$store.dispatch('getUserInfo');
                                    this.register = false;
                                })
                                .catch(e => {
                                    this.$message.error(e.error);
                                });
                        })
                        .catch(e => {
                            this.$message.error(e.error);
                        });
                }
            });
        }
    },
    components: {
        Agreement,
        Agreement2
    }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
    margin-top: 25vh !important;
    position: relative;
}
.tips {
    position: absolute;
    top: 30px;
    left: 232px;
    display: flex;
    font-weight: 500;
    .tips_con {
        color: #000000;
        line-height: 34px;
        cursor: pointer;
        position: relative;
        font-size: 24px;
        &::after {
            content: '';
            width: 48px;
            height: 2px;
            background: #000000;
            border-radius: 2px;
            position: absolute;
            left: 24px;
            bottom: -2px;
        }
    }
}
/deep/ .el-form {
    margin-left: 115px !important;
    margin-top: 40px !important;
}
/deep/ .el-form-item__content {
    display: flex;
    border-bottom: solid 1px #f2f3f5;
}
/deep/ .el-input__inner {
    border: none;
}
.el-icon-user {
    font-size: 24px;
    margin-top: 8px;
}
.el-icon-unlock {
    font-size: 24px;
    margin-top: 8px;
}
.verification_code_img {
    width: 24px;
    height: 24px;
    margin-top: 8px;
}
.code1 {
    width: 100px;
    height: 34px;
    background: #c8c9cc;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    line-height: 34px;
    cursor: pointer;
}
.code {
    width: 100px;
    height: 34px;
    background: #c8c9cc;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    line-height: 34px;
}
.xieyi {
    margin-left: 115px;
    margin-bottom: 39px;
}
.sub-btn {
    margin-left: 115px;
    text-align: center;
    cursor: pointer;
    .btn-block {
        width: 330px;
        height: 40px;
        background: #01a041;
        border-radius: 4px;
        font-size: 12px;
        color: #ffffff;
        font-weight: bold;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .btn-blocks {
        width: 330px;
        height: 40px;
        background: #f2f3f5;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        color: #939599;
        line-height: 40px;
    }
}
.img-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: 2px;
}
/deep/ .el-checkbox__input {
    display: none;
}
/deep/ .el-checkbox__label {
    padding-left: 0px;
    position: relative;
    // display: flex;
    // align-items: center;
}
</style>
