<template>
    <div>
        <!-- <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言 -->
        <message></message>
    </div>
</template>

<script>
import Message from '../components/popup/Message.vue';
export default {
    components: { Message }
};
</script>
