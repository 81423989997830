var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"center":"","visible":_vm.login,"width":"560px"},on:{"update:visible":function($event){_vm.login=$event}}},[_c('div',{staticClass:"tips"},[_c('div',{staticClass:"tips_con",class:{ active: _vm.active === 0 },on:{"click":function($event){_vm.active = 0}}},[_vm._v("账号登录")]),_c('div',{staticClass:"tips_con",class:{ active: _vm.active === 1 },on:{"click":function($event){_vm.active = 1}}},[_vm._v("免密登录")])]),_c('div',{staticClass:"login"},[(_vm.active === 0)?_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"0"}},[_c('el-form-item',{attrs:{"prop":"username","rules":{
                        required: true,
                        pattern: /^1[3-9]\d{9}$/,
                        message: '请输入正确的手机号',
                        trigger: 'blur'
                    }}},[_c('span',{staticClass:"el-icon-user"}),_c('el-input',{staticStyle:{"width":"300px"},attrs:{"placeholder":"请输入账号/手机号"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('div',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.Login.apply(null, arguments)}}},[_c('el-form-item',{attrs:{"prop":"password","rules":{ required: true, message: '请输入', trigger: 'blur' }}},[_c('span',{staticClass:"el-icon-unlock"}),_c('el-input',{staticStyle:{"width":"300px"},attrs:{"placeholder":"请输入密码","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1)],1):_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"0"}},[_c('el-form-item',{attrs:{"prop":"phone","rules":{
                        pattern: /^1[3-9]\d{9}$/,
                        message: '请输入正确的手机号',
                        trigger: 'blur'
                    }}},[_c('span',{staticClass:"el-icon-user"}),_c('el-select',{staticStyle:{"width":"90px"},attrs:{"placeholder":"请选择"},model:{value:(_vm.form.region),callback:function ($$v) {_vm.$set(_vm.form, "region", $$v)},expression:"form.region"}},_vm._l((_vm.list),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('el-input',{staticStyle:{"width":"210px"},attrs:{"placeholder":"请输入手机号"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('div',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.loginPhone.apply(null, arguments)}}},[_c('el-form-item',{attrs:{"prop":"code","rules":{ required: true, message: '请输入', trigger: 'blur' }}},[_c('span',{staticClass:"icon"}),_c('div',[_c('el-input',{staticStyle:{"width":"300px"},attrs:{"maxlength":"4","placeholder":"请输入短信验证码"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}},[_c('el-link',{staticClass:"code",attrs:{"slot":"append","type":"primary","underline":false},on:{"click":_vm.send},slot:"append"},[_vm._v(_vm._s(_vm.time === 0 ? '发送验证码' : `重新发送${_vm.time}s`))])],1)],1)])],1)],1),_c('div',{staticClass:"forgot_password",on:{"click":_vm.forgotPassword}},[_vm._v("忘记密码？")]),_c('div',{staticClass:"btn"},[(_vm.active === 0)?_c('div',{staticClass:"btn_one",on:{"click":_vm.Login}},[_vm._v("登录")]):_c('div',{staticClass:"btn_one",on:{"click":_vm.loginPhone}},[_vm._v("登录")]),_c('div',{staticClass:"btn_two",on:{"click":_vm.registeredAccount}},[_vm._v("注册账号")])])],1)]),_c('Agreement3',{ref:"agreement3"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }