var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-content"},[(
            this.$route.name !== 'register' &&
            this.$route.name !== 'login' &&
            this.$route.name !== 'authenticationfail' &&
            this.$route.name !== 'authenticationsfail' &&
            this.$route.name !== 'registration' &&
            this.$route.name !== 'enterprise' &&
            this.$route.name !== 'allsearch' &&
            this.$route.name !== 'authentication' &&
            this.$route.name !== 'authentications' &&
            this.$route.name !== 'personal'
        )?_c('div',[_c('div',{staticClass:"menu",style:({ position: _vm.fixed ? 'fixed' : 'absolute' })},[_c('el-menu',{ref:"menu",attrs:{"mode":"horizontal","background-color":_vm.$colors.warn,"text-color":"#fff","active-text-color":"#fff","unique-opened":"","router":""}},[_vm._l((_vm.menus),function(item){return [(item.children.length === 0)?_c('el-menu-item',{key:item.id,staticStyle:{"width":"160px"},attrs:{"index":`${item.path || item.id}`,"name":item.id}},[_vm._v(_vm._s(item.name))]):_c('el-submenu',{key:item.id,staticStyle:{"width":"208px"},attrs:{"name":item.id,"index":`${item.path || item.id}`,"popper-class":"popup-menu"}},[_c('template',{slot:"title"},[_vm._v(_vm._s(item.name))]),_vm._l((item.children),function(child){return _c('el-menu-item',{key:child.id,attrs:{"index":`${child.path || child.id}`}},[_vm._v(_vm._s(child.name))])})],2)]})],2)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }