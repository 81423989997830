<template>
    <div>
        <el-container id="app" direction="vertical" v-if="loginRegistration == false">
            <page-header></page-header>
            <el-container direction="vertical">
                <el-main class="main" style="width: 100%; overflow: hidden">
                    <keep-alive :include="keeps">
                        <router-view class="appContainer" />
                    </keep-alive>
                </el-main>
                <el-footer height="257px">
                    <div class="footer-content">
                        <div class="footer-content_con">
                            <div class="center-item center-item_one">
                                <div class="link">友情链接</div>
                                <a href="http://jsip.jiangsu.gov.cn/" class="link_con" target="_blank"
                                    >江苏省知识产权局</a
                                >
                                <a href="https://www.jstec.com.cn/" class="link_con" target="_blank"
                                    >江苏省技术产权交易市场</a
                                >
                                <a href="https://ip.jsipp.cn/" class="link_con" target="_blank"
                                    >江苏省知识产权公共服务平台</a
                                >
                                <a href="https://www.jspc.org.cn/" class="link_con" target="_blank"
                                    >江苏省生产力促进中心</a
                                >
                            </div>
                            <div class="center-item center-item_two">
                                <div class="link"></div>
                                <a href="http://amr.nanjing.gov.cn/" class="link_con" target="_blank"
                                    >南京市市场监督管理局（市知识产权局）</a
                                >
                                <a href="http://www.njdbc.cn/" class="link_con" target="_blank">南京专利代办处</a>
                                <a href="https://www.bxgxq.com/" class="link_con" target="_blank">白下高新智慧园区</a>
                                <a class="link_con" target="_blank">智戎知识产权公共服务平台</a>
                                <!-- <a href="http://gxt.jiangsu.gov.cn/" class="link_con">江苏省工业和信息化厅</a>
                            <a href="http://kxjst.jiangsu.gov.cn/" class="link_con">江苏省科学技术厅</a> -->
                            </div>
                            <!-- <div class="center-item center-item_two">
                            <div class="link"></div>
                            <a href="http://www.smejs.cn/" class="link_con">江苏省中小企业发展中心</a>
                            <a href="http://fzggw.jiangsu.gov.cn/" class="link_con">江苏省发展和改革委员会</a>
                            <a href="https://etax.jiangsu.chinatax.gov.cn/sso/login" class="link_con">国家税务总局江苏省电子税务局</a>
                            <a href="http://scjgj.jiangsu.gov.cn/col/col70164/index.html" class="link_con">江苏工商网上登记系统</a>
                        </div>
                        <div class="center-item center-item_two">
                            <div class="link"></div>
                            <a href="http://www.njkj.gov.cn/" class="link_con">南京市科学技术委员会</a>
                            <a href="http://fgw.nanjing.gov.cn/" class="link_con">南京市发展和改革委员会</a>
                            <a href="http://swj.nanjing.gov.cn/" class="link_con">南京市商务局</a>
                            <a href="http://jxw.nanjing.gov.cn/" class="link_con">南京市工业和信息化局</a>
                            <a href="http://www.njsmzx.gov.cn/" class="link_con">南京市中小企业公共服务平台</a>
                        </div> -->
                            <!-- <div class="center-item center-item_three">
                            <div class="link">核心服务</div>
                            <router-link to="" class="link_con">专利运营</router-link>
                            <router-link to="" class="link_con">培训服务</router-link>
                            <router-link to="" class="link_con">综合服务</router-link>
                        </div> -->
                            <div class="center-item center-item_four">
                                <div class="link">联系我们</div>
                                <router-link to="/about#connect" class="link_con">电话：{{ phone }}</router-link>
                                <router-link to="/about#connect" class="link_con">邮箱：{{ email }}</router-link>
                                <router-link to="/about#connect" class="link_con">地址：{{ address }}</router-link>
                            </div>
                            <!-- <div>
                            <img src="../assets/erweima-jpg-01.png" alt="" class="footer-content_con_img" />
                            <div class="link_con">官方微信公众号</div>
                        </div> -->
                        </div>
                        <!-- <div class="left"></div>
                    <div style="padding-right: 80px">
                        <div class="img">
                            <img src="../assets/erweima-jpg-01.png" alt="" />
                        </div>
                        <div class="name">关注我们</div>
                    </div>
                    <div>
                        <div class="img">
                            <img src="../assets/erweima-jpg-02.png" alt="" />
                        </div>
                        <div class="name">联系客服</div>
                    </div>
                    <div class="center">
                        <div class="center-item">
                            <div class="link">核心服务</div>
                            <router-link to="/policy" class="link">IP融合运营</router-link>
                            <router-link to="/patent?caseType=DECLASSIFICATION" class="link">IP融合服务</router-link>
                            <router-link to="/intellectualProperty?type=DECLASSIFICATION" class="link"
                                >IP融合资源</router-link
                            >
                        </div>
                        <div class="center-item">
                            <router-link to="/about" class="link">关于我们</router-link>
                            <router-link to="/about#intro" class="link">平台简介</router-link>
                            <router-link to="/about#honor" class="link">平台荣誉</router-link>
                            <router-link to="/about#connect" class="link">客户服务</router-link>
                        </div>
                    </div>
                    <img src="../assets/dibu-wenzi-png.png" style="display: block; width: 430px; height: 28px" alt="" />
                    <img
                        src="../assets/banquan_png@3x.png"
                        style="display: block; width: 32px; height: 32px; margin-bottom: 15px"
                        alt=""
                    /> -->
                        <!-- <div class="tips"> -->
                        <!-- 页面所涉及产品信息仅用于展示目的，本网站及本网站展商合作方保留更改网页上所列产品相关信息的权利，恕不另行通知。 -->
                        <!-- </div> -->
                    </div>
                    <div class="footer-content_pIc record_jump" @click="recordJump">
                        白下高新区知识产权运营服务平台 © 2019 - 2022 版权所有 苏ICP备17027521号
                    </div>
                </el-footer>
            </el-container>

            <pop-components></pop-components>
        </el-container>
        <el-container id="app" direction="vertical" v-else>
            <el-main class="main" style="width: 100%; overflow: hidden">
                <keep-alive :include="keeps">
                    <router-view />
                </keep-alive>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import NavMenu from '../components/NavMenu.vue';
import PageHeader from '../components/PageHeader.vue';
import PopComponents from './PopComponents.vue';
import BreadPage from '../components/page/BreadPage.vue';
// import Login from './Login.vue';
export default {
    components: { PageHeader, NavMenu, PopComponents, BreadPage },
    name: 'Index',
    metaInfo() {
        return {
            title: this.pageTitle
        };
    },
    computed: {
        ...mapState(['keeps']),
        pageTitle() {
            return this.$route.meta.title || '白下知识产权';
        }
    },
    data() {
        return {
            address: '',
            email: '',
            phone: '',
            loginRegistration: false
        };
    },
    watch: {
        $route() {
            if (this.$route.name == 'login' || this.$route.name == 'register') {
                this.loginRegistration = true;
            } else {
                this.loginRegistration = false;
            }
        }
    },
    mounted() {
        if (this.$route.name == 'login' || this.$route.name == 'register') {
            this.loginRegistration = true;
        } else {
            this.loginRegistration = false;
        }
        this.$http.get('/sysConfig/get/ADDRESS').then(res => {
            this.address = res.value;
        });
        this.$http.get('/sysConfig/get/EMAil').then(res => {
            this.email = res.value;
        });
        this.$http.get('/sysConfig/get/PHONE').then(res => {
            this.phone = res.value;
        });
    },
    methods: {
        recordJump() {
            location.href = 'https://beian.miit.gov.cn/';
        }
    }
};
</script>

<style lang="less" scoped>
#app {
    min-height: 100vh;
}

.main > div {
    flex-shrink: 0;
}

.appContainer {
    padding: 0 0 60px;
    flex-grow: 1;
    background: linear-gradient(180deg, #01a041 1%, rgba(1, 160, 65, 0) 100%);
    background-size: 100% 300px;
    background-repeat: no-repeat;
    background-color: @bg;
}
.main {
    padding: 0;

    .flex-col();
}
.record_jump {
    cursor: pointer;
}
.footer-content {
    width: 100%;
    height: 210px;
    background: #373737;
    padding-top: 20px;
    box-sizing: border-box;
    .footer-content_con {
        width: 1000px;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
        // .center-item_one {
        //     margin-right: 48px;
        // }
        .center-item_two {
            // margin-right: 132px;
            margin-top: 25px;
        }
        // .center-item_three {
        //     margin-right: 120px;
        // }
        // .center-item_four {
        //     margin-right: 50px;
        // }
        .link {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #ffffff;
            margin-bottom: 20px;
            margin-top: 5px;
        }
        .link_con {
            width: 100%;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: 12px;
            display: block;
        }
        .footer-content_con_img {
            width: 100px;
            height: 100px;
            margin-bottom: 4px;
        }
    }
}
.footer-content_pIc {
    width: 100%;
    height: 47px;
    background: #282727;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
    line-height: 47px;
}
.el-footer {
    padding: 0px;
}
// .el-footer {
//     background-color: @warn;
//     padding: 0;
//     position: relative;
//     .flex-col();
//     &::before {
//         content: '';
//         height: 6px;
//         display: block;
//         background-color: @prim;
//     }

//     .footer-content {
//         justify-content: center;
//         // padding-bottom: 55px;
//         padding-top: 42px;
//         .flex();
//         // .left {
//         //     min-width: 240px;
//         // }
//         .name {
//             font-size: 12px;
//             font-weight: 600;
//             color: #ffffff;
//             line-height: 17px;
//             text-align: center;
//         }
//         .center {
//             width: 438px;
//             .flex();
//             .center-item {
//                 padding: 0 80px;
//                 .link {
//                     &:first-child {
//                         font-size: 12px;
//                         color: #ffffff;
//                         line-height: 17px;
//                     }
//                     display: block;
//                     font-size: 12px;
//                     color: #acacac;
//                     line-height: 17px;
//                     margin-top: 12px;
//                     cursor: pointer;

//                     &:hover {
//                         color: @prim;
//                         text-decoration: underline;
//                     }
//                 }

//                 &:not(:last-child) {
//                     border-right: 1px solid #acacac;
//                 }
//             }
//         }
//         position: relative;

//         .tips {
//             position: absolute;
//             bottom: 20px;
//             font-size: 12px;
//             color: #acacac;
//             line-height: 17px;
//             text-align: center;
//         }
//     }

//     .pIc {
//         background-color: rgba(0, 0, 0, 0.2);
//         font-size: 12px;
//         color: #acacac;
//         line-height: 17px;
//         text-align: center;
//         line-height: 36px;
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         right: 0;
//     }
// }

.img {
    background-color: #fff;
    padding: 4px;
    margin-bottom: 10px;
    img {
        display: block;
        width: 68px;
        height: 68px;
    }
}
</style>
