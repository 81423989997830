<template>
    <div>
        <el-header height="90px" class="header_top" v-if="!toMeta">
            <div class="header_top_con">
                <div class="header_top_left" @click="all('/')">
                    <img src="../assets/nav_logo@3x (1).png" alt="" class="header_top_img" />
                    <div class="header_top_label"></div>
                    <div class="header_top_title">白下高新区知识产权运营服务平台</div>
                </div>
                <div class="header_top_right">
                    <div class="header_top_login" @click="$router.push('/login')" v-if="!isLogin">[登录]</div>
                    <div
                        class="header_top_login"
                        @click="
                            $router.push({
                                path: '/register',
                                query: {
                                    type: 1
                                }
                            })
                        "
                        v-if="!isLogin"
                    >
                        [注册]
                    </div>
                    <div class="btn-list" v-if="isLogin">
                        <el-dropdown @command="onCommand" style="margin-right: 10px" trigger="click">
                            <div class="img">消息</div>
                            <div class="title" v-if="NewsList > 0">
                                {{ NewsList }}
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item class="newsAll" command="allNews" style="word-break: keep-all"
                                    >查看全部
                                </el-dropdown-item>
                                <div class="right-tabbox-newnotice">
                                    <el-tabs v-model="activeName">
                                        <el-tab-pane label="消息" name="first"></el-tab-pane>
                                        <!-- <el-tab-pane label="留言" name="message"></el-tab-pane> -->
                                    </el-tabs>
                                </div>
                                <div v-if="newsList != 0">
                                    <div v-for="(item, index) in newsLists" :key="index">
                                        <div class="news">
                                            <div class="news-l">
                                                <img
                                                    src="../assets/xiaoxi_icon_xitong@3x.png"
                                                    alt=""
                                                    class="news-l_img"
                                                />
                                                <div class="news-con">
                                                    <div class="txt">
                                                        <div class="txt1">系统消息</div>
                                                        <div class="txt2">{{ item.createdAt }}</div>
                                                    </div>
                                                    <div class="txt3">{{ item.content }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="border" v-if="newsLists.length > 1"></div>
                                    </div>
                                </div>
                                <div class="empty" v-else>
                                    <i class="el-icon-takeaway-box" style="margin-left: 21px"></i>
                                    <div style="font-size: 16px">暂无信息</div>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-dropdown @command="onCommand" style="margin-left: 20px" trigger="click">
                            <span class="el-dropdown-link">
                                <img style="display: block" :src="avatarBox" /><i
                                    class="el-icon-arrow-down el-icon--right"
                                ></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="nickname" style="word-break: keep-all"
                                    >{{ userInfo.nickname }}
                                </el-dropdown-item>
                                <div class="border1"></div>
                                <el-dropdown-item command="edit" style="word-break: keep-all"
                                    >个人资料
                                </el-dropdown-item>
                                <el-dropdown-item command="supply" style="word-break: keep-all"
                                    >我的供给
                                </el-dropdown-item>
                                <el-dropdown-item command="demand" style="word-break: keep-all"
                                    >我的需求
                                </el-dropdown-item>
                                <div class="border1"></div>
                                <el-dropdown-item command="logout">退出登录 </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="header_top_navigation" @click="navigation"><i class="el-icon-menu"></i>网站导航</div>
                </div>
                <div
                    class="header_top_navigation_con"
                    ref="header_top_navigation_con"
                    v-if="navigationDisplay"
                    tabindex="0"
                    @blur="navigationNone"
                >
                    <img src="../assets/juxing@3x.png" alt="" class="header_top_navigation_con_img" />
                    <div class="navigation_information" v-for="(item, index) in navigationTab" :key="index">
                        <div class="navigation_tab">{{ item.title }}</div>
                        <div
                            class="navigation_details"
                            v-for="(item, index) in item.navigationDetails"
                            :key="index"
                            @click="details(item.name)"
                        >
                            <div class="navigation_details_con">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="header">
            <div class="left" v-if="this.$route.name !== 'allsearch'">
                <router-link :to="{ name: 'home' }">
                    <img src="../assets/logo.png" alt="" />
                </router-link>
                <div class="header-r"></div>
                <div class="title">
                    <img src="../assets/top-png-biaoti@3x.png" alt="" />
                </div>
            </div>
            <div class="left2" v-else>
                <router-link :to="{ name: 'home' }">
                    <img src="../assets/logo.png" alt="" />
                </router-link>
                <div class="header-r"></div>
                <div class="title">
                    <img src="../assets/top-png-biaoti@3x.png" alt="" />
                </div>
            </div>

            <div class="right" v-if="this.$route.name !== 'allsearch'">
                <search-input
                    size="mini"
                    v-model="search"
                    class="search"
                    placeholder="搜索..."
                    @focus="all('/Allsearch')"
                    clearable
                >
                    <i slot="append" class="el-icon-search"></i>
                </search-input>
                <div class="btn-list" v-else>
                    <el-button size="mini" plain type="warning" @click="all('/Login')">登录</el-button>
                    <div class="line"></div>
                    <el-button size="mini" plain type="warning" @click="all('/Register')">注册</el-button>
                </div>
            </div>
        </div> -->
        </el-header>
        <el-header height="90px" class="header_tops" v-else>
            <div class="header_top_con">
                <div class="header_top_left" @click="all('/')">
                    <img src="../assets/nav_logo@3x (2).png" alt="" class="header_top_img" />
                    <div class="header_top_labels"></div>
                    <div class="header_top_titles">白下高新区知识产权运营服务平台</div>
                </div>
                <div class="header_top_right">
                    <div class="header_top_logins" @click="$router.push('/login')" v-if="!isLogin">[登录]</div>
                    <div
                        class="header_top_logins"
                        @click="
                            $router.push({
                                path: '/register',
                                query: {
                                    type: 1
                                }
                            })
                        "
                        v-if="!isLogin"
                    >
                        [注册]
                    </div>
                    <div class="btn-list" v-if="isLogin">
                        <el-dropdown @command="onCommand" style="margin-right: 10px" trigger="click">
                            <div class="imgs">消息</div>
                            <div class="title" v-if="NewsList > 0">
                                {{ NewsList }}
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item class="newsAll" command="allNews" style="word-break: keep-all"
                                    >查看全部
                                </el-dropdown-item>
                                <div class="right-tabbox-newnotice">
                                    <el-tabs v-model="activeName">
                                        <el-tab-pane label="消息" name="first"></el-tab-pane>
                                        <!-- <el-tab-pane label="留言" name="message"></el-tab-pane> -->
                                    </el-tabs>
                                </div>
                                <div v-if="newsList != 0">
                                    <div v-for="(item, index) in newsLists" :key="index">
                                        <div class="news">
                                            <div class="news-l">
                                                <img
                                                    src="../assets/xiaoxi_icon_xitong@3x.png"
                                                    alt=""
                                                    class="news-l_img"
                                                />
                                                <div class="news-con">
                                                    <div class="txt">
                                                        <div class="txt1">系统消息</div>
                                                        <div class="txt2">{{ item.createdAt }}</div>
                                                    </div>
                                                    <div class="txt3">{{ item.content }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="border" v-if="newsLists.length > 1"></div>
                                    </div>
                                </div>
                                <div class="empty" v-else>
                                    <i class="el-icon-takeaway-box" style="margin-left: 21px"></i>
                                    <div style="font-size: 16px">暂无信息</div>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-dropdown @command="onCommand" style="margin-left: 20px" trigger="click">
                            <span class="el-dropdown-link">
                                <img style="display: block" :src="avatarBox" /><i
                                    class="el-icon-arrow-down el-icon--right"
                                ></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="nickname" style="word-break: keep-all"
                                    >{{ userInfo.nickname }}
                                </el-dropdown-item>
                                <div class="border1"></div>
                                <el-dropdown-item command="edit" style="word-break: keep-all"
                                    >个人资料
                                </el-dropdown-item>
                                <el-dropdown-item command="supply" style="word-break: keep-all"
                                    >我的供给
                                </el-dropdown-item>
                                <el-dropdown-item command="demand" style="word-break: keep-all"
                                    >我的需求
                                </el-dropdown-item>
                                <div class="border1"></div>
                                <el-dropdown-item command="logout">退出登录 </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="header_top_navigations" @click="navigation"><i class="el-icon-menu"></i>网站导航</div>
                </div>
                <div
                    class="header_top_navigation_con"
                    ref="header_top_navigation_con"
                    v-if="navigationDisplay"
                    tabindex="0"
                    @blur="navigationNone"
                >
                    <img src="../assets/juxing@3x.png" alt="" class="header_top_navigation_con_img" />
                    <div class="navigation_information" v-for="(item, index) in navigationTab" :key="index">
                        <div class="navigation_tab">{{ item.title }}</div>
                        <div
                            class="navigation_details"
                            v-for="(item, index) in item.navigationDetails"
                            :key="index"
                            @click="details(item.name)"
                        >
                            <div class="navigation_details_con">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="header">
            <div class="left" v-if="this.$route.name !== 'allsearch'">
                <router-link :to="{ name: 'home' }">
                    <img src="../assets/logo.png" alt="" />
                </router-link>
                <div class="header-r"></div>
                <div class="title">
                    <img src="../assets/top-png-biaoti@3x.png" alt="" />
                </div>
            </div>
            <div class="left2" v-else>
                <router-link :to="{ name: 'home' }">
                    <img src="../assets/logo.png" alt="" />
                </router-link>
                <div class="header-r"></div>
                <div class="title">
                    <img src="../assets/top-png-biaoti@3x.png" alt="" />
                </div>
            </div>

            <div class="right" v-if="this.$route.name !== 'allsearch'">
                <search-input
                    size="mini"
                    v-model="search"
                    class="search"
                    placeholder="搜索..."
                    @focus="all('/Allsearch')"
                    clearable
                >
                    <i slot="append" class="el-icon-search"></i>
                </search-input>
                <div class="btn-list" v-else>
                    <el-button size="mini" plain type="warning" @click="all('/Login')">登录</el-button>
                    <div class="line"></div>
                    <el-button size="mini" plain type="warning" @click="all('/Register')">注册</el-button>
                </div>
            </div>
        </div> -->
        </el-header>
        <RegistrationReset ref="registrationReset"></RegistrationReset>
        <Agreement3 ref="agreement3"></Agreement3>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SearchInput from './SearchInput';
import EventBus from '../eventBus';
// import Agreement2 from '../components/popup/Agreement2.vue';
import RegistrationReset from '../components/popup/registrationReset.vue';
import Agreement3 from '../components/popup/Agreement3.vue';
export default {
    name: 'Header',
    data() {
        return {
            toMeta: false,
            search: '',
            region: '',
            activeName: 'first',
            newsList: [],
            NewsList: '',
            authorities: [],
            navigationTab: [
                {
                    title: '知产咨询动态',
                    navigationDetails: [
                        {
                            name: '平台动态'
                        },
                        {
                            name: '通知公告'
                        },
                        {
                            name: '知产百科'
                        }
                    ]
                },
                {
                    title: '知产转化数据',
                    navigationDetails: [
                        {
                            name: '供给侧'
                        },
                        {
                            name: '需求侧'
                        },
                        {
                            name: '技术经理人'
                        },
                        {
                            name: '成交案例'
                        }
                    ]
                },
                {
                    title: '知产运营服务',
                    navigationDetails: [
                        {
                            name: '信息服务'
                        },
                        {
                            name: '咨询服务'
                        },
                        {
                            name: '转化运用服务'
                        },
                        {
                            name: '法律服务'
                        },
                        {
                            name: '培训服务'
                        }
                    ]
                },
                {
                    title: '关于我们',
                    navigationDetails: [
                        {
                            name: '中心简介'
                        },
                        // {
                        //     name: '发展历程'
                        // },
                        // {
                        //     name: '荣誉资质'
                        // },
                        {
                            name: '联系我们'
                        }
                    ]
                }
            ],
            navigationDisplay: false,
            toNavigationDisplay: false
        };
    },
    created() {
        const receiveUserId = this.userInfo.id;
        const isRead = false;
        this.$http.post('/information/all', { query: { receiveUserId, isRead } }, { body: 'json' }).then(res => {
            this.NewsList = res.numberOfElements;
            this.newsList = res.content;
        });
    },
    computed: {
        ...mapState(['loginHome']),
        newsLists() {
            return [...this.newsList].filter((item, index) => {
                return index < 4;
            });
        },
        newsType() {
            return this.activeName == 'first' ? true : false;
        },
        ...mapState(['userInfo', 'institutionInfo', 'personalInfo', 'loginType']),
        type() {
            return this.userInfo.authorities.find(item => {
                return item.name == 'ROLE_INSTITUTION' || item.name == 'ROLE_PERSONAL';
            });
        },
        avatarBox() {
            return this.userInfo.avatar
                ? this.userInfo.avatar
                : 'https://baixiaip.oss-cn-hangzhou.aliyuncs.com/image/2022-09-06-17-04-39jfwMIPtC.png';
        }
    },

    watch: {
        loginHome() {
            if (this.loginHome) {
                this.$refs.registrationReset.login = true;
            }
        },
        $route() {
            if (
                this.$route.name == 'newsDetail' ||
                this.$route.name == 'technicalManagerDetail' ||
                this.$route.name == 'successDetail' ||
                this.$route.name == 'technologyProductDemandDetail' ||
                this.$route.name == 'copyrightDemandDetail' ||
                this.$route.name == 'logoDemandDetail' ||
                this.$route.name == 'patentDemandDetail' ||
                this.$route.name == 'patentDetail' ||
                this.$route.name == 'logoDetail' ||
                this.$route.name == 'copyrightDetail' ||
                this.$route.name == 'technologyProductDetail' ||
                this.$route.name == 'expertDetail' ||
                this.$route.name == 'intellectualPropertyDetail' ||
                this.$route.name == 'digitalDetail' ||
                this.$route.name == 'digitalDetails'
            ) {
                this.toMeta = true;
            } else if (
                this.$route.name == 'newsList' ||
                this.$route.name == 'patent' ||
                this.$route.name == 'patentDemand' ||
                this.$route.name == 'technicalManager' ||
                this.$route.name == 'successCases' ||
                this.$route.name == 'agent' ||
                this.$route.name == 'about'
            ) {
                this.navigationDisplay = false;
                this.toMeta = false;
            } else {
                this.toMeta = false;
            }
        }
    },
    mounted() {
        if (
            this.$route.name == 'newsDetail' ||
            this.$route.name == 'technicalManagerDetail' ||
            this.$route.name == 'successDetail' ||
            this.$route.name == 'technologyProductDemandDetail' ||
            this.$route.name == 'copyrightDemandDetail' ||
            this.$route.name == 'logoDemandDetail' ||
            this.$route.name == 'patentDemandDetail' ||
            this.$route.name == 'patentDetail' ||
            this.$route.name == 'logoDetail' ||
            this.$route.name == 'copyrightDetail' ||
            this.$route.name == 'technologyProductDetail' ||
            this.$route.name == 'expertDetail' ||
            this.$route.name == 'intellectualPropertyDetail' ||
            this.$route.name == 'digitalDetail' ||
            this.$route.name == 'digitalDetails'
        ) {
            this.toMeta = true;
        } else {
            this.toMeta = false;
        }
        console.log('141414', this.loginHome);
        if (this.loginHome) {
            this.$refs.registrationReset.login = true;
        }
    },
    methods: {
        navigation() {
            this.navigationDisplay = !this.navigationDisplay;
            if (this.toNavigationDisplay) {
                this.navigationDisplay = false;
                this.toNavigationDisplay = false;
            }
            if (this.navigationDisplay) {
                this.$nextTick(() => {
                    this.$refs.header_top_navigation_con.focus();
                });
            }
        },
        details(name) {
            if (name === '平台动态') {
                this.$router.push('/newsList?flag=1');
                this.$store.commit('modifyFlag', 1);
            } else if (name === '通知公告') {
                this.$router.push('/newsList?flag=2');
                this.$store.commit('modifyFlag', 2);
            } else if (name === '知产百科') {
                this.$router.push('/newsList?flag=3');
                this.$store.commit('modifyFlag', 3);
            } else if (name === '供给侧') {
                this.$router.push('/patent?caseType=GENERAL');
            } else if (name === '需求侧') {
                this.$router.push('/patentDemand?caseType=GENERAL');
            } else if (name === '技术经理人') {
                this.$router.push('/technicalManager');
            } else if (name === '成交案例') {
                this.$router.push('/successCases?category=GENERAL');
            } else if (name === '信息服务') {
                this.$router.push('/agent?navigationId=95');
            } else if (name === '咨询服务') {
                this.$router.push('/agent?navigationId=101');
            } else if (name === '转化运用服务') {
                this.$router.push('/agent?navigationId=1014');
            } else if (name === '法律服务') {
                this.$router.push('/agent?navigationId=111');
            } else if (name === '培训服务') {
                this.$router.push('/agent?navigationId=127');
            } else if (name === '中心简介') {
                this.$router.push('/about#intro');
            } else if (name === '发展历程') {
                this.$router.push('/about#history');
            } else if (name === '荣誉资质') {
                this.$router.push('/about#honor');
            } else if (name === '联系我们') {
                this.$router.push('/about#connect');
            }
        },
        ...mapMutations(['updateUserInfo', 'loginTypeFn']),
        login() {
            EventBus.$emit('login', '登录');
        },
        batchRead() {
            const receiveUserId = this.userInfo.id;
            let batchRead = this.NewsList.numberOfElements;
            this.$http
                .post('/information/batchRead', { query: { receiveUserId, batchRead } }, { body: 'json' })
                .then(res => {
                    this.NewsList = res;
                });
        },
        navigationNone() {
            this.navigationDisplay = false;
            this.toNavigationDisplay = true;
        },
        onCommand(command) {
            if (command === 'nickname') {
                this.$router.push('/security');
            } else if (command === 'edit') {
                this.$router.push('/userEdit');
                // if (this.type) {
                //     if (this.type.name == 'ROLE_INSTITUTION') {
                //         if (this.institutionInfo.status == 'PASS') {
                //             this.$router.push('/userEdit');
                //         } else if (this.institutionInfo.status == 'DENY') {
                //             this.$router.push('/Authenticationsfail');
                //         } else {
                //             this.$router.push('/Authentications');
                //         }
                //     } else if (this.type.name == 'ROLE_PERSONAL') {
                //         if (this.personalInfo.status == 'PASS') {
                //             this.$router.push('/userEdit');
                //         } else if (this.personalInfo.status == 'DENY') {
                //             this.$router.push('/Authenticationfail');
                //         } else {
                //             this.$router.push('/Authentication');
                //         }
                //     }
                // } else {
                //     this.$router.push('/userEdit');
                // }
            } else if (command === 'logout') {
                localStorage.removeItem('webToken');
                // this.loginTypeFn(false);
                this.updateUserInfo(null);
                this.$$router.push('/Home');
                // this.$refs.registrationReset.login = true;
            } else if (command === 'supply') {
                this.$router.replace('/publish/patent?caseType=GENERAL');
            } else if (command === 'demand') {
                this.$router.replace('/publish/patentDemand?caseType=GENERAL');
            } else if (command === 'allNews') {
                this.$router.push('/newsInfo');
                this.batchRead();
            }
        }
    },
    components: {
        SearchInput,
        RegistrationReset,
        Agreement3
    }
};
</script>

<style lang="less" scoped>
.header_top {
    width: 100%;
    background: #01a041;
    // border-radius: 2px;
    .header_top_con {
        height: 90px;
        width: 1200px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        position: relative;
        .header_top_navigation_con {
            outline: 0 none;
            position: absolute;
            top: 83px;
            z-index: 9999;
            width: 1200px;
            height: 213px;
            background: #ffffff;
            border-radius: 2px;
            padding: 37px 26px 19px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            .navigation_information {
                width: 266px;
                height: 157px;
                border-radius: 4px;
                border: 1px solid #f2f3f5;
                padding: 20px 30px 0px;
                box-sizing: border-box;
                // display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                position: relative;
                .navigation_details {
                    display: inline-block;
                    height: 26px;
                    margin-bottom: 20px;
                }
                .navigation_details_con {
                    width: 100px;
                    height: 26px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #313233;
                    line-height: 26px;
                    cursor: pointer;
                    &:hover {
                        color: #01a041;
                    }
                }
                .navigation_details:nth-of-type(odd) {
                    text-align: right;
                }
                .navigation_tab {
                    width: 112px;
                    height: 24px;
                    text-align: center;
                    background: #ffffff;
                    font-size: 18px;
                    font-weight: bold;
                    color: #01a041;
                    line-height: 24px;
                    position: absolute;
                    top: -15px;
                    left: 81px;
                    z-index: 99;
                }
            }
        }
        .header_top_navigation_con_img {
            width: 12px;
            height: 8px;
            position: absolute;
            top: -8px;
            right: 36px;
        }
    }
    .header_top_left {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .header_top_img {
        width: 170px;
        height: 58px;
        margin-right: 5px;
    }
    .header_top_label {
        width: 1px;
        height: 42px;
        background: rgba(255, 255, 255, 0.24);
        border-radius: 1px;
        margin-right: 6px;
    }
    .header_top_title {
        width: 410px;
        // height: 24px;
        font-size: 24px;
        font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
        font-weight: bold;
        color: #ffffff;
        // line-height: 36px;
    }
    .header_top_right {
        display: flex;
        align-items: center;
        .header_top_login {
            width: 44px;
            height: 24px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.5);
            line-height: 24px;
            margin-right: 20px;
            cursor: pointer;
        }
        .header_top_navigation {
            cursor: pointer;
            margin-left: 4px;
            // width: 67px;
            height: 24px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 24px;
            .el-icon-menu:before {
                width: 24px;
                height: 24px;
                margin-right: 5px;
            }
        }
    }
    .el-icon--right {
        color: #ffffff;
    }
}
.header_tops {
    width: 100%;
    background: #ffffff;
    .header_top_con {
        height: 90px;
        width: 1200px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        position: relative;
        .header_top_navigation_con {
            outline: 0 none;
            position: absolute;
            top: 83px;
            z-index: 9999;
            width: 1200px;
            height: 213px;
            background: #ffffff;
            border-radius: 2px;
            padding: 37px 26px 19px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            .navigation_information {
                width: 266px;
                height: 157px;
                border-radius: 4px;
                border: 1px solid #f2f3f5;
                padding: 20px 30px 0px;
                box-sizing: border-box;
                // display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                position: relative;
                .navigation_details {
                    display: inline-block;
                    height: 26px;
                    margin-bottom: 20px;
                }
                .navigation_details_con {
                    width: 100px;
                    height: 26px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #313233;
                    line-height: 26px;
                    cursor: pointer;
                    &:hover {
                        color: #01a041;
                    }
                }
                .navigation_details:nth-of-type(odd) {
                    text-align: right;
                }
                .navigation_tab {
                    width: 112px;
                    height: 24px;
                    text-align: center;
                    background: #ffffff;
                    font-size: 18px;
                    font-weight: bold;
                    color: #01a041;
                    line-height: 24px;
                    position: absolute;
                    top: -15px;
                    left: 81px;
                    z-index: 99;
                }
            }
        }
        .header_top_navigation_con_img {
            width: 12px;
            height: 8px;
            position: absolute;
            top: -8px;
            right: 36px;
        }
    }
    .header_top_left {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .header_top_img {
        width: 170px;
        height: 58px;
        margin-right: 5px;
    }
    .header_top_labels {
        width: 1px;
        height: 42px;
        background: rgba(0, 0, 0, 0.24);
        border-radius: 1px;
        margin-right: 6px;
    }
    .header_top_titles {
        width: 410px;
        // height: 24px;
        font-size: 24px;
        font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
        font-weight: bold;
        color: #727172;
        // line-height: 36px;
    }
    .header_top_right {
        display: flex;
        align-items: center;
        .header_top_logins {
            width: 44px;
            height: 24px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c8c9cc;
            line-height: 24px;
            margin-right: 20px;
            cursor: pointer;
        }
        .header_top_navigations {
            cursor: pointer;
            margin-left: 4px;
            // width: 67px;
            height: 24px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #727172;
            line-height: 24px;
            .el-icon-menu:before {
                width: 24px;
                height: 24px;
                margin-right: 5px;
            }
        }
    }
    .el-icon--right {
        color: #727172;
    }
}
.selectA {
    /deep/ .el-input {
        .el-input__inner {
            border-width: 0px;
            height: 46px;
            font-size: 0;
            background: none;
            line-height: 16px;
        }
    }
}
/deep/ .el-tabs__nav-scroll {
    padding-left: 13px;
}
/deep/ .el-tabs__item {
    font-weight: bold;
    font-size: 14px;
    width: 300px;
}
/deep/ .el-dropdown-menu__item {
    // font-weight: bold;
    text-align: center;
    color: #626366;
}
.right-tabbox-newnotice /deep/.el-tabs__nav-wrap::after {
    height: 1px;
}
.newsAll {
    position: absolute;
    right: 3px;
    z-index: 99;
    font-size: 12px;
    font-weight: 400;
    color: #c8c9cc;
}
.el-dropdown-menu__item:not(.is-disabled):hover {
    color: #01a041;
}
/deep/ .el-avatar {
    background: none;
    margin-right: 5px;
}
.el-header {
    background-position: center center;
    background-size: 100% 100%;
    z-index: 20;
}
.border1 {
    margin: 6px 16px;
    height: 1px;
    background: #f2f3f5;
}
.header {
    .center-content();
    height: 160px;
    justify-content: space-between;
    .flex();
    .right {
        width: 240px;
    }
}

.left {
    .flex();
    .header-r {
        border: 1px solid #00684f;
        height: 96px;
    }
    img {
        width: auto;
        height: 117px;
        display: block;
        cursor: pointer;
    }

    .title {
        img {
            height: 117px;
        }
    }
}
.left2 {
    margin-left: 256px;
    .flex();
    .header-r {
        border: 1px solid #00684f;
        height: 96px;
    }
    img {
        width: auto;
        height: 117px;
        width: 306px;
        display: block;
        cursor: pointer;
    }

    .title {
        img {
            height: 117px;
            width: 370px;
        }
    }
}
.el-dropdown-link {
    .flex();
    margin-right: 14px;
}
.border {
    border: 0.5px solid #f2f3f5;
    margin: 5px 0 0 50px;
}
.empty {
    font-size: 20px;
    color: #ccc;
    padding-left: 120px;
    padding-bottom: 20px;
}
.news {
    display: flex;
    width: 300px;
    padding: 10px;
    justify-content: space-between;
    .news-l {
        display: flex;
        .news-con {
            .txt {
                .flex();
                width: 245px;
                justify-content: space-between;
                margin-bottom: 5px;
                .txt1 {
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: bold;
                    color: #000000;
                }
                .txt2 {
                    font-size: 14px;
                    font-weight: 400;
                    color: #c8c9cc;
                    line-height: 24px;
                }
            }
            .txt3 {
                width: 230px;
                font-size: 14px;
                font-weight: 400;
                color: #c8c9cc;
                overflow: hidden; /*超出部分隐藏*/
                white-space: nowrap; /*不换行*/
                text-overflow: ellipsis; /*超出部分文字以...显示*/
                line-height: 24px;
            }
        }
    }

    .time {
        font-size: 14px;
        font-weight: 400;
        color: #c8c9cc;
        line-height: 24px;
    }
}
.btn-list {
    .flex();
    justify-content: flex-end;
    // margin-top: 24px;
    cursor: pointer;
    // margin-right: -15px;
    .img {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 24px;
        // width: 34px;
        // margin-top: 3px;
        // height: 34px;
    }
    .imgs {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #727172;
        line-height: 24px;
    }
    .title {
        position: absolute;
        right: -7px;
        top: -7px;
        width: 14px;
        height: 14px;
        background: #ff8700;
        color: #ffffff;
        font-size: 11px;
        text-align: center;
        line-height: 14px;
        border-radius: 7px;
        border: 1px solid #ffffff;
    }
    .el-button {
        border-width: 0;
        background-color: transparent;
        &:hover {
            text-decoration: underline;
        }
        &:hover,
        &:focus,
        &:visited {
            color: @warn;
        }
    }
    .el-button + .el-button {
        margin-left: 0;
    }

    .line {
        width: 1px;
        height: 10px;
        background-color: @warn;
    }
    .el-icon-chat-dot-square {
        font-size: 28px;
        color: #ccc;
        margin-right: 21px;
    }
    .avatars {
        margin-right: 12px;
    }
    img {
        width: 30px;
        height: 30px;
        margin: 0 2px 0 -12px;
        border-radius: 50%;
    }
}
/deep/ .is-active {
    color: #01a041;
    position: relative;
    &::after {
        content: '';
        width: 26px;
        height: 1px;
        background: #01a041;
        position: absolute;
        bottom: 1px;
        left: 0;
    }
}
.news-l_img {
    width: 36px;
    height: 36px;
    margin-right: 8px;
}
</style>
